import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LineItem } from '@Types/cart/LineItem';
import { LoadingIcon } from 'components/commercetools-ui/icons/loading';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { BUNDLE_ATTRIBUTE_NAME, getBundledPrice, getSelectedBundleLabel } from 'helpers/utils/bundleItemsHelpers';
import debounce from 'lodash.debounce';
import { useCart } from 'frontastic';
import Image from 'frontastic/lib/image';
//import SplitItemModal from '@Bic/components/commercetools-ui/cart/split-item';
import { useBusinessUnitStateContext } from 'frontastic/provider/BusinessUnitState';

interface Props {
  lineItem: LineItem;
  goToProductPage: (_url: string) => void;
  editItemQuantity: (lineItemId: string, newQuantity: number) => void;
  removeItem: (lineItemId: string) => void;
  isModificationForbidden?: boolean;
}

const Item = ({ lineItem, goToProductPage, editItemQuantity, removeItem, isModificationForbidden }: Props) => {
  const { businessUnit } = useBusinessUnitStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isSplitted, setIsSplitted] = useState(false);
  const currentCountRef = useRef(lineItem.count);
  const [isSplitModalOpen, setIsSplitModalOpen] = useState(false);
  const { formatMessage } = useFormat({ name: 'cart' });
  const qtyMode = businessUnit.custom.fields.quantityMode ?? "sku";
  const [step] = useState(qtyMode === "sku" ? parseInt(lineItem.variant?.attributes['bic_qty_increment'] || '1') : parseInt(lineItem.variant?.attributes['number_of_sku_by_outer'] || '1'));
  const [min] = useState(qtyMode === "sku" ? parseInt(lineItem.variant?.attributes['bic_qty_increment'] || step) : parseInt(lineItem.variant?.attributes['number_of_sku_by_outer'] || step));
  const [max] = useState(qtyMode === "sku" ?  parseInt(lineItem.variant?.attributes['bic_max_sale_qty'] || 999999999) : 999999999);
  const [count, setCount] = useState(Number(min));
  const bundles = lineItem.variant?.attributes?.[BUNDLE_ATTRIBUTE_NAME];
  const { data: cart } = useCart();
  const isPreBuyCart = useState(cart?.isPreBuyCart);
  const [inventoryNone] = useState(cart?.inventoryMode == 'None');

  const handleRemoveItem = async () => {
    setIsLoading(true);
    await removeItem(lineItem.lineItemId);
    setIsLoading(false);
  };

  const handleChange = (e) => {
    setCount(e.target.value);
    if (true === e.currentTarget.closest('input').reportValidity()) {
      const newCount = Number(e.target.value);
      if (Number.isNaN(newCount) || inventoryNone || newCount <= lineItem.variant?.availability?.availableQuantity) {
        debounced(e.target.value);
      }
    }
  };

  const debounced = useCallback(
    debounce(async (value) => {
      setIsLoading(true);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      await editItemQuantity(lineItem.lineItemId, value);
      setIsLoading(false);
    }, 500),
    [],
  );

  useEffect(() => {
    console.log('lineItem', lineItem)
    setIsSplitted(false);
    //setIsSplitted(lineItem?.shippingDetails?.valid && lineItem?.shippingDetails?.targets?.length > 0);
  }, [lineItem]);

  useEffect(() => {
    setCount(lineItem.count);
  }, [lineItem.count]);

  // Calculate finalPrice
  const discountedPrice =
    lineItem?.discountedPrice?.centAmount !== undefined ? lineItem?.discountedPrice?.centAmount : 0;

  const discountedAmount =
    lineItem?.discounts[0]?.discountedAmount.centAmount !== undefined
      ? lineItem?.discounts[0]?.discountedAmount.centAmount
      : 0;

  let finalPrice;
  if (discountedPrice === 0) {
    finalPrice = lineItem?.price?.centAmount - discountedAmount;
  } else {
    finalPrice = discountedPrice - discountedAmount;
  }

  return (
    <>
      <tr
        className={`line-item ${isLoading ? 'disabled' : ''} ${
          lineItem.variant?.attributes?.['narcotic'] ? 'bg-red-100' : ''
        }`}
      >
        <td className="">
          <table className="inner-table">
            <tbody>
              <tr>
                <td className="td-line-item__image">
                  <Image src={lineItem.variant.images[0]} alt={lineItem.name} className="object-contain object-center" />
                </td>
                <td className="td-line-item__details">
                  <p className="td__name mb-2" onClick={() => goToProductPage(lineItem._url)}>
                    {lineItem.name}
                  </p>
                  <p className="td-other-details td-details__sku">
                    <label>Sku:</label> {lineItem.variant.sku}
                  </p>
                  {/*lineItem.variant.attributes['bic_plant_number'] && <p className="td-other-details td-details__sku mt-1">
                    <label>{formatMessage({ id: 'directShippingAvailableFromFactory', defaultMessage: 'Direct shipping available from factory' })}</label> : <span className='font-bold'>{lineItem.variant.attributes['bic_plant_number']}</span>
                  </p>*/}
                  {/*Object.keys(lineItem.variant.attributes).length !== 0 &&
                    lineItem.variant.attributes.volume_uc !== undefined && (
                      <p className="td-other-details td-details__sku">
                        <label>{formatMessage({ id: 'productVolume', defaultMessage: 'Product volume: ' })}</label>{' '}
                        {lineItem.variant.attributes.volume_uc.toFixed(2)} dm<sup>3</sup>
                      </p>
                    )*/}
                  {!!bundles?.length && (
                    <div className="flex flex-col">
                      {bundles.map((bundle: LineItem) => (
                        <div className="td-other-details td-details__sku" key={bundle.lineItemId}>
                          <label className="">{`${bundle.name}: `}</label>
                          <span className="text-xs">{getSelectedBundleLabel(bundle.variant, bundle.name)}</span>
                        </div>
                      ))}
                    </div>
                  )}
                  <p className="td-other-details td-details__other-buttons mt-6 hidden lg:block">
                    {/*<button
                      type="button"
                      className={`mt-1 mr-4 font-bold ${isSplitted ? 'bg-green-300' : 'bg-white'}`}
                      onClick={() => setIsSplitModalOpen(true)}
                      title="split quantity to different shipping addresses"
                    >
                      Split Shipping
                      </button>*/}
                    {false === isModificationForbidden && <button
                      type="button"
                      className="btn-remove mt-1 mr-1 font-bold"
                      onClick={handleRemoveItem}
                    >
                      Remove
                    </button>
                    }
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </td>

        <td className="p-1 text-center">
          <div className="product-qty-wrapper mx-auto">
            {!lineItem.custom?.fields?.free_product &&
              <input
                value={count}
                type="number"
                disabled={isLoading}
                onChange={handleChange}
                className="product-qty-input p-0"
                step={step}
                min={min}
                max={max}
              />
            }
            {lineItem.custom?.fields?.free_product &&
              <input
                value={count}
                type="number"
                disabled={true}
                className="product-qty-input p-0"
              />
            }
          </div>
          <p
            className={`td-other-details td-details__availability mt-2 ${
              !inventoryNone && count > lineItem.variant.availability?.availableQuantity && 'text-red-500'
            }`}
          >
            {/*inventoryNone && (
              <>
                <label>In Stock</label> {!inventoryNone && lineItem.variant.availability?.availableQuantity}
              </>
            )*/}
            {!inventoryNone && (
              <>
                {!isPreBuyCart && lineItem.variant.availability?.availableQuantity > 0 && (
                  <>
                    <label>In Stock:</label> {lineItem.variant.availability?.availableQuantity}
                  </>
                )}
                {!isPreBuyCart && lineItem.variant.availability?.availableQuantity <= 0 && <label>Out of stock</label>}
                {isPreBuyCart && <>&nbsp;</>}
              </>
            )}
          </p>
        </td>
        <td className="p-1_text price text-left sm:text-center w-full">
          <div className='lg:hidden font-normal'>Outer quantity: <span className='font-bold'>{(0 < lineItem.variant.attributes.number_of_sku_by_outer ?? 0) && (lineItem.count/lineItem.variant.attributes.number_of_sku_by_outer).toFixed(3)}</span></div>
          <div className="hidden lg:block">
                {(0 < lineItem.variant.attributes.number_of_sku_by_outer ?? 0) && (new Intl.NumberFormat('fr-FR', {style: 'decimal', minimumFractionDigits: 2}).format(lineItem.count/lineItem.variant.attributes.number_of_sku_by_outer))}
          </div>
        </td>
        <td className="p-1_text price text-left sm:text-center w-full">
          <div className='lg:hidden font-normal'>Pallets: <span className='font-bold'>{(0 < lineItem.variant.attributes.number_of_sku_by_pallet ?? 0) && (lineItem.count/lineItem.variant.attributes.number_of_sku_by_pallet).toFixed(3) }</span></div>
          <div className="hidden lg:block">
            {(0 < lineItem.variant.attributes.number_of_sku_by_pallet ?? 0) && (new Intl.NumberFormat('fr-FR', {style: 'decimal', minimumFractionDigits: 2}).format(lineItem.count/lineItem.variant.attributes.number_of_sku_by_pallet)) }
          </div>
        </td>
        <td className={`p-1_text price text-right w-full ${discountedAmount > 0 || discountedPrice > 0 ? 'discounted' : ''}`}>
          <div className='lg:hidden font-normal text-left'>Price:&nbsp; 
            <span className='font-bold'>
              {(discountedAmount > 0 || discountedPrice > 0) && (
                  CurrencyHelpers.formatForCurrency(
                    CurrencyHelpers.addCurrency(
                      CurrencyHelpers.formatToMoneyWithCurrency(finalPrice / 100, lineItem.price.currencyCode),
                    ),
                  )
              )}
              {CurrencyHelpers.formatForCurrency(lineItem.price)}
            </span>
            </div>
            <div className="hidden lg:block">
            {(discountedAmount > 0 || discountedPrice > 0) && (
              <div className="price">
                {CurrencyHelpers.formatForCurrency(
                  CurrencyHelpers.addCurrency(
                    CurrencyHelpers.formatToMoneyWithCurrency(finalPrice / 100, lineItem.price.currencyCode),
                  ),
                )}
              </div>
            )}
            <div className={`${discountedAmount > 0 || discountedPrice > 0 ? 'old-price' : ''}`}>
              {CurrencyHelpers.formatForCurrency(lineItem.price)}
            </div>
          </div>
        </td>
        <td className="p-1_text price text-right w-full">
          <div className='lg:hidden font-normal text-left'>Total:&nbsp;
            <span className='font-bold'>
              {CurrencyHelpers.formatForCurrency({...lineItem.totalPrice, minimumFractionDigits: 2})}
              {isLoading && (
                <div className="line-item__loading">
                  <LoadingIcon className="mt-1/2 h-4 w-4 animate-spin text-black" />
                </div>
              )}
            </span>
          </div>
          <div className="hidden lg:block">
            {CurrencyHelpers.formatForCurrency({...lineItem.totalPrice, minimumFractionDigits: 2})}
            {isLoading && (
              <div className="line-item__loading">
                <LoadingIcon className="mt-1/2 h-4 w-4 animate-spin text-black" />
              </div>
            )}
          </div>
        </td>

        <td className="w-full lg:hidden">
          <p className="td-other-details td-details__other-buttons mt-4 mb-6">
            {/*<button
              type="button"
              className={`mt-1 mr-4 font-bold ${isSplitted ? 'bg-green-300' : 'bg-white'}`}
              onClick={() => setIsSplitModalOpen(true)}
              title="split quantity to different shipping addresses"
            >
              Split Shipping
          </button>*/}
            <button
              type="button"
              className="btn-remove mt-1 mr-1 font-bold"
              onClick={handleRemoveItem}
              disabled={isModificationForbidden}
            >
              Remove
            </button>
          </p>
        </td>
      </tr>
      {/*isSplitModalOpen && (
        <SplitItemModal lineItem={lineItem} open={isSplitModalOpen} onClose={() => setIsSplitModalOpen(false)} />
      )*/}
    </>
  );
};

export default Item;
